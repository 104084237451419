import { usePageData } from "~core/hooks/use-page";
import { Fragment } from "react";

import styles from "./TopStories.module.css";
import adStyles from "./homepage-native-ads.module.css";

import ArticleDisplay from "~/features/article-list/ArticleDisplay";
import ArticleRow, { IMAGE_LAYOUT_OPTIONS } from "./ArticleRow";
import NativeAd from "~features/ads/NativeAd";
import SubPromo from "./SubPromo";

export default function TopStories() {
  const { topStories: stories } = usePageData();

  /* TODO: Optimize this better later.  We want the border NOT to appear on columnMiddle on mobile, but
   * we need to get this work done, so the dev team made a deliberate choice to check for the last element
   * and paste a special class on that element only.
   */
  let columnMiddleStories = stories.slice(1, 4);

  return (
    <section className={styles.topStoriesLayout}>
      <section className={styles.columnHero}>
        <ArticleDisplay article={stories[0]} layout="hero" />
      </section>
      <section className={styles.columnMiddle}>
        {columnMiddleStories.map((article, index) => {
          let layout = IMAGE_LAYOUT_OPTIONS.desktopLarge;
          if (index === 1) {
            layout = IMAGE_LAYOUT_OPTIONS.desktopHide;
          }

          /* TODO: Optimize this better later. */
          return (
            <ArticleRow
              articleData={article}
              imageLayout={layout}
              key={article.contentful_id}
              lastElement={index === columnMiddleStories.length - 1}
              showCategory={true}
            />
          );
        })}
      </section>
      <section className={styles.columnLatest}>
        {stories.slice(4, 9).map((article, i) => {
          return (
            <Fragment key={article.contentful_id}>
              {i === 1 && (
                <>
                  <NativeAd position="native-topstories" className={adStyles.hpNativeAd} />
                  {/*
                   * SubPromo will only render if a user is not logged in and if there is an
                   * error rendering an ad and gpt.js appends the `is-empty` class to the ad
                   */}
                  <SubPromo />
                </>
              )}
              <ArticleRow
                articleData={article}
                imageLayout={IMAGE_LAYOUT_OPTIONS.alwaysSmall}
                showCategory={true}
              />
            </Fragment>
          );
        })}
      </section>
    </section>
  );
}
