import { useUserAccess } from "@sciam/piano/react";
import styles from "./SubPromo.module.css";
import { cx } from "~lib/utils";

const assets = import.meta.glob(["./*.jpg", "./*.gif"], {
  as: "url",
  eager: true,
});

const SUB_PROMO_TEXT = {
  MAIN_TEXT_LARGE: "Digital Access for $1",
  MAIN_TEXT_SMALL: "Unlimited Access for $1",
  SUB_TEXT_LARGE: "Sign up now to get 60 days of digital access",
  SUB_TEXT_SMALL: "Sign up now to get 90 days of Unlimited access",
};

export default function SubPromo({ size = "small" }) {
  const { hasAccess } = useUserAccess();
  const isSmall = size === "small";
  const subPromoImg = isSmall
    ? assets[`./subPromoSmallArt.gif`]
    : assets[`./subPromoDigitalArt.jpg`];

  if (hasAccess) {
    return;
  }

  return (
    <>
      <a
        href="/getsciam/"
        className={isSmall ? styles.subPromoContainer : styles.subPromoContainer__Large}
      >
        <img
          src={subPromoImg}
          alt=""
          className={isSmall ? styles.subPromoImg : styles.subPromoImg__Large}
        />
        <div
          className={isSmall ? styles.subPromoTextContainer : styles.subPromoTextContainer__Large}
        >
          <h2 className={isSmall ? styles.subPromoMainText : styles.subPromoMainText__Large}>
            {isSmall ? SUB_PROMO_TEXT.MAIN_TEXT_SMALL : SUB_PROMO_TEXT.MAIN_TEXT_LARGE}
          </h2>
          <p className={styles.subPromoSubText}>
            {isSmall ? SUB_PROMO_TEXT.SUB_TEXT_SMALL : SUB_PROMO_TEXT.SUB_TEXT_LARGE}
          </p>
        </div>
        <span className={styles.subPromoCaret} />
      </a>
      {isSmall ? <hr className={styles.subPromoDivider} /> : null}
    </>
  );
}
