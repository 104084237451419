// Similar to display article but with special Homepage behavior variations.
import { handleTrackClick } from "~lib/analytics/handleTrackClick";
import { getBestArticleId } from "~/lib/getBestArticleId";
import { cx, formatAuthors } from "~utils";
import { Kicker } from "~ui/Kicker";

import styles from "./article-row.module.css";

/**
 * Layout enum. Always refer by key, not number.
 */
export const IMAGE_LAYOUT_OPTIONS = {
  none: 0,
  alwaysLarge: 1, // @TODO - Not implemented yet
  alwaysSmall: 2,
  desktopLarge: 3,
  desktopHide: 4,
};

/**
 *
 * @param {{
 *  articleData: object,
 *  clickTrackKey: string,
 *  imageLayout: number - use the enums from IMAGE_LAYOUT_OPTIONS
 * }} param0
 * @returns
 */
export default function ArticleRow({
  articleData,
  clickTrackKey = "unspecified-article-row-",
  imageLayout = IMAGE_LAYOUT_OPTIONS.alwaysSmall,
  lastElement,
  isDarkMode = false,
  showCategory = false,
  showPublishedDate = true,
}) {
  let { image_url } = articleData;
  const {
    authors,
    release_date,
    date_published,
    display_date,
    url,
    display_title,
    image_alt_text,
    category,
    subtype,
    digital_column,
  } = articleData;
  const storyAuthors = formatAuthors(authors);

  // fallback img for podcasts w/o one
  if (!image_url && subtype === "Podcast") {
    // @TODO: use an asset import for this
    image_url = "https://www.scientificamerican.com/static/science-quickly-logo.jpg";
  }

  // Create classes for image layout controls
  // The row will be styled differently if we need a large image,
  // changing the flex direction at whatever breakpoint
  let rowClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.alwaysLarge) {
    rowClass = styles.rowImageLarge;
  }
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopLarge) {
    rowClass = styles.rowDesktopLarge;
  }

  // These figures/img will be styled different based on layout controls
  let imageWrapperLayoutClass = null;
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.desktopHide) {
    imageWrapperLayoutClass = styles.imageLayoutDesktopHide;
  }

  return (
    <article
      className={cx(
        styles.rowWrapper,
        lastElement ? styles.borderBottomMobile : "",
        isDarkMode ? styles.isDarkMode : "",
      )}
    >
      <div className={cx(styles.row, rowClass)}>
        {imageLayout !== IMAGE_LAYOUT_OPTIONS.none && image_url && (
          <div className={cx(styles.imageWrapper, imageWrapperLayoutClass)}>
            <a
              className={styles.link}
              href={url}
              onClick={(e) => {
                handleTrackClick(clickTrackKey + getBestArticleId(articleData), e);
              }}
            >
              <figure className={styles.figure}>
                {/* @TODO Size logic under many different circumstances */}
                <ArticleRowResponsiveImage
                  className={styles.image}
                  image_url={image_url}
                  imageLayout={imageLayout}
                  alt={image_alt_text || ""}
                  loading="lazy"
                />
              </figure>
            </a>
          </div>
        )}

        <div className={styles.contentWrapper}>
          <Kicker
            category={category}
            display_date={display_date}
            release_date={date_published || release_date}
            showCategory={showCategory}
            showPublishedDate={showPublishedDate}
            subtype={subtype}
            digital_column={digital_column}
            isDarkMode={isDarkMode}
          />
          <a
            className={styles.link}
            href={url}
            onClick={(e) => {
              handleTrackClick(clickTrackKey + getBestArticleId(articleData), e);
            }}
          >
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: display_title }}></h2>
          </a>
          <p className={styles.authors}>{storyAuthors}</p>
        </div>
      </div>
    </article>
  );
}

function ArticleRowResponsiveImage({ image_url, imageLayout, className, ...attributes }) {
  // Fastly params for image resizing
  // The "crop" param will crop the image to the specified aspect ratio.
  // crop's "smart" param will center the image on the most interesting part such as a face or a horizon.
  // The "optimize" param will moderately compress the image. Ignored on GIFs.
  const square = "crop=1:1,smart&optimize=medium";
  const wide = "crop=16:9,smart&optimize=medium";

  // Small images should be 80px wide and square
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.alwaysSmall) {
    return (
      <img
        className={className}
        src={image_url}
        srcSet={`${image_url}?w=80&${square} 80w, ${image_url}?w=120&${square} 120w, ${image_url}?w=160&${square} 160w`}
        sizes="80px"
        {...attributes}
      />
    );
  }

  // Everything should render at ~280px wide above 900px
  return (
    <picture className={className}>
      <source
        media="(min-width: 900px)"
        srcSet={`${image_url}?w=280&${wide} 280w, ${image_url}?w=420&${wide} 420w, ${image_url}?w=560&${wide} 560w`}
        // Try to force 1.5x image on 2x screens
        sizes="(min-resolution: 1dppx) 140px, (min-resolution: 1.5ppx) 280px"
      />
      <img
        src={image_url}
        srcSet={`${image_url}?w=80&${square} 80w, ${image_url}?w=120&${square} 120w, ${image_url}?w=160&${square} 160w`}
        sizes="80px"
        {...attributes}
      />
    </picture>
  );
}
