import { usePageData } from "~core/hooks/use-page";
import ArticleDisplay from "~/features/article-list/ArticleDisplay";
import ArticleRow from "./ArticleRow";
import styles from "./LatestPodcasts.module.css";

export default function LatestPodcasts() {
  const { latestPodcasts: podcasts } = usePageData();
  const mainPodcast = podcasts[0];
  const secondaryPodcasts = podcasts.slice(1, 5);

  return (
    <section className={styles.latest_podcasts}>
      <h2 className={styles.latest_podcasts_main_text}>Podcasts</h2>
      <div className={styles.latest_podcasts_layout}>
        <div className={styles.latest_podcasts_column__main_podcast}>
          <ArticleDisplay
            article={mainPodcast}
            layout="hero"
            isDarkMode
            showCategory={false}
            showDek={false}
          />
        </div>
        <div className={styles.latest_podcasts_column_secondary}>
          {secondaryPodcasts.map((podcast, index) => {
            return (
              <ArticleRow
                articleData={podcast}
                key={podcast.contentful_id}
                showCategory={true}
                lastElement={index === secondaryPodcasts.length - 1}
                isDarkMode
              />
            );
          })}
        </div>
        <a href="/podcasts/" className={styles.latest_podcasts_btn}>
          More Podcasts
        </a>
      </div>
    </section>
  );
}
