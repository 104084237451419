import ExploreTopics from "~ui/Dropdown/ExploreTopics";
import { handleTrackClick } from "~lib/analytics/handleTrackClick";
import styles from "./explore-view-stories.module.css";

export default function ExploreViewStories() {
  // datalayer variable name to track clicks on the View All Stories link
  let clickTrackKeyViewAll = "homepage-viewall-stories";

  /* Links to send to Explore Topics Button */
  const exploreTopicsLinks = [
    { url: "/health/", label: "Health", clickTrackParam: "health" },
    { url: "/mind-and-brain/", label: "Mind & Brain", clickTrackParam: "mind-and-brain" },
    { url: "/environment/", label: "Environment", clickTrackParam: "environment" },
    { url: "/technology/", label: "Technology", clickTrackParam: "technology" },
    {
      url: "/space-and-physics/",
      label: "Space & Physics",
      clickTrackParam: "space-and-physics",
    },
    { url: "/biology/", label: "Biology", clickTrackParam: "biology" },
    { url: "/math/", label: "Math", clickTrackParam: "math" },
    { url: "/chemistry/", label: "Chemistry", clickTrackParam: "chemistry" },
    { url: "/social-sciences/", label: "Social Sciences", clickTrackParam: "social-sciences" },
    { url: "/videos/", label: "Videos", clickTrackParam: "videos" },
    { url: "/podcasts/", label: "Podcasts", clickTrackParam: "podcasts" },
    { url: "/section/opinion/", label: "Opinion", clickTrackParam: "opinion" },
    { url: "/games/", label: "Games", clickTrackParam: "games" },
  ];

  // Register a click track when the View All Stories link is clicked
  function handleViewAllClick(e) {
    handleTrackClick(clickTrackKeyViewAll, e);
  }

  return (
    <div className={styles.exploreViewStoriesBtnModuleWrap}>
      <div className={styles.exploreViewStoriesBtnWrap}>
        <ExploreTopics
          links={exploreTopicsLinks}
          buttonTitle={"Explore Topics"}
          clickTrackKey={"homepage-explore-topics-"}
        />
        <a href="/latest/" onClick={handleViewAllClick} className={styles.viewAllStoriesLnk}>
          View All Stories
        </a>
      </div>
    </div>
  );
}
